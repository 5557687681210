import { cloneElement, useState, ReactElement, MouseEvent } from "react";
import { Link as RouterLink } from "react-router-dom";
import logo from "assets/images/logo.png";
import {
  AppBar as MuiAppBar,
  Box,
  Button,
  Container,
  Drawer,
  IconButton,
  Link,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Stack,
  Toolbar,
  Typography,
  useScrollTrigger,
} from "@mui/material";

import MenuIcon from "@mui/icons-material/Menu";
import { FormattedMessage } from "react-intl";
import LanguageToggle from "./LanguageToggle";

const NavBar = () => {
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  const toggleMobileMenu = () => {
    setIsMobileMenuOpen(!isMobileMenuOpen);
  };

  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(event.currentTarget);
  };
  const handleClose = () => {
    setAnchorEl(null);
  };

  return (
    <div className="navbar-wrapper">
      <div className="homepage_appbar">
        <div className="homepage_appbar_container">
          <div className="homepage_appbar_logo">
            <RouterLink to="/">
              <img src={logo} alt="Freeze Carbon" width="169" />
            </RouterLink>
          </div>
          <div className="homepage_appbar_right_toggle">
            <LanguageToggle className="mobile" />

            <div className="homepage_appbar_right">
              <Stack
                direction="row"
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "block",
                    lg: "none",
                    xl: "none",
                  },
                }}
                spacing={{ xs: 1.5, md: 2.5 }}
              >
                <IconButton
                  size="large"
                  aria-controls={open ? "basic-menu" : undefined}
                  aria-haspopup="true"
                  aria-expanded={open ? "true" : undefined}
                  onClick={handleClick}
                >
                  <MenuIcon />
                </IconButton>
                <Menu
                  id="basic-menu"
                  anchorEl={anchorEl}
                  open={open}
                  onClose={handleClose}
                  MenuListProps={{
                    "aria-labelledby": "basic-button",
                  }}
                >
                  {/* <MenuItem
                    onClick={handleClose}
                    component={RouterLink}
                    to="/home"
                    id="home"
                  >
                    <FormattedMessage id="home" defaultMessage="Home" />
                  </MenuItem> */}
                  <MenuItem
                    onClick={handleClose}
                    component={RouterLink}
                    to="/services"
                  >
                    <FormattedMessage id="services" defaultMessage="Services" />
                  </MenuItem>
                  <MenuItem
                    onClick={handleClose}
                    component={RouterLink}
                    to="/our-projects"
                  >
                    <FormattedMessage
                      id="ourProjects"
                      defaultMessage="Our Projects"
                    />
                  </MenuItem>

                  <MenuItem
                    onClick={handleClose}
                    component={RouterLink}
                    to="/testimonials"
                  >
                    <FormattedMessage
                      id="customers"
                      defaultMessage="Customers"
                    />
                  </MenuItem>

                  {/* <MenuItem
                    onClick={handleClose}
                    component={RouterLink}
                    to="/knowledge"
                  >
                    <FormattedMessage
                      id="knowledge"
                      defaultMessage="Knowledge"
                    />
                  </MenuItem> */}

                  <MenuItem
                    onClick={handleClose}
                    component={RouterLink}
                    to="/about"
                  >
                    <FormattedMessage id="about" defaultMessage="About" />
                  </MenuItem>

                  {/* <MenuItem
                    onClick={handleClose}
                    component={RouterLink}
                    to="/getting-started"
                  >
                    <FormattedMessage
                      id="exploreProjects"
                      defaultMessage="Explore projects"
                    />
                  </MenuItem> */}
                  <MenuItem
                    onClick={handleClose}
                    component={RouterLink}
                    to="https://portal.freezecarbon.com"
                  >
                    <a
                      color="inherit"
                      href="https://portal.freezecarbon.com"
                      target="_blank"
                      style={{ textDecoration: "none", color: "inherit" }}
                    >
                      <FormattedMessage id="signIn" defaultMessage="Sign in" />
                    </a>
                  </MenuItem>
                </Menu>
              </Stack>

              <div
                className={`homepage_appbar_nav ${
                  isMobileMenuOpen ? "open" : ""
                }`}
              >
                <div className="homepage_appbar_nav_links">
                  {/* <RouterLink to="/home" className="homepage_appbar_nav_link">
                    <FormattedMessage id="home" defaultMessage="Home" />
                  </RouterLink> */}
                  <RouterLink
                    to="/services"
                    className="homepage_appbar_nav_link"
                  >
                    <FormattedMessage id="services" defaultMessage="Services" />
                  </RouterLink>

                  <RouterLink
                    to="/our-projects"
                    className="homepage_appbar_nav_link"
                  >
                    <FormattedMessage
                      id="ourProjects"
                      defaultMessage="Our Projects"
                    />
                  </RouterLink>

                  <RouterLink
                    to="/testimonials"
                    className="homepage_appbar_nav_link"
                  >
                    <FormattedMessage
                      id="customers"
                      defaultMessage="Customers"
                    />
                  </RouterLink>

                  {/* <RouterLink
                    to="/knowledge"
                    className="homepage_appbar_nav_link"
                  >
                    <FormattedMessage
                      id="knowledge"
                      defaultMessage="Knowledge"
                    />
                  </RouterLink> */}

                  <RouterLink to="/about" className="homepage_appbar_nav_link">
                    <FormattedMessage id="about" defaultMessage="About" />
                  </RouterLink>
                </div>

                {/* <RouterLink
                  to="/getting-started"
                  className="homepage_appbar_nav_get_started"
                >
                  <FormattedMessage
                    id="exploreProjects"
                    defaultMessage="Explore projects"
                  />
                </RouterLink> */}
                <RouterLink
                  className="homepage_appbar_nav_link"
                  to="https://portal.freezecarbon.com"
                >
                  <a
                    color="inherit"
                    href="https://portal.freezecarbon.com"
                    target="_blank"
                    style={{ textDecoration: "none", color: "inherit" }}
                  >
                    <FormattedMessage id="signIn" defaultMessage="Sign in" />
                  </a>
                </RouterLink>
              </div>
            </div>
            <LanguageToggle className="desktop" />
          </div>
        </div>
      </div>
    </div>
  );
};

export default NavBar;
