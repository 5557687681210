import * as React from "react";

import ToggleButton from "@mui/material/ToggleButton";
import ToggleButtonGroup from "@mui/material/ToggleButtonGroup";
import { activeItem } from "store/slices/menu";
import { getLocales } from "react-native-localize";

function LanguageToggle({ className }) {
  const browserLanguage = navigator.language;
  const systemLanguage = getLocales()[0].languageCode;

  const routesToGerman = [
    "/kompensation",
    "/carbon-credits",
    "/co2-zertifikate",
    "/klimaschutzprojekt",
  ];

  const shouldSetToGerman = routesToGerman.some((route) =>
    window.location.pathname.includes(route)
  );

  React.useEffect(() => {
    if (shouldSetToGerman) {
      setLanguage("de-DE");
    }
  }, [shouldSetToGerman]);

  const languageToUse =
    systemLanguage.startsWith("de") || browserLanguage.startsWith("de")
      ? "de-DE"
      : "en-US";

  const selectedItem =
    sessionStorage.getItem("browserLanguage") || languageToUse;

  const [language, setLanguage] = React.useState(selectedItem);

  const handleChange = (event) => {
    // Dispatch an action to update the language state in Redux
    const selectedLanguage = event.target.value;
    console.log(event.target.value);
    const newLanguage = selectedLanguage === "de-DE" ? "de-DE" : "en-US";
    setLanguage(newLanguage);
    console.log("--------------------------", newLanguage);
    sessionStorage.setItem("browserLanguage", newLanguage);
    window.location.reload();
  };

  return (
    <div className={`language-toggle language-toggle-${className}`}>
      <ToggleButtonGroup
        value={language}
        exclusive
        onChange={handleChange} // Call handleChange when the toggle changes
        aria-label="language toggle"
        color="primary"
        size="small"
      >
        <ToggleButton value="en-US" aria-label="english">
          EN
        </ToggleButton>
        <ToggleButton value="de-DE" aria-label="german">
          DE
        </ToggleButton>
      </ToggleButtonGroup>
    </div>
  );
}

export default LanguageToggle;
