import "./App.css";

import React, { useState } from "react";
import { useEffect } from "react";
import { CookieConsent, getCookieConsentValue } from "react-cookie-consent";
import { getCurrencies, getLocales } from "react-native-localize";

import { RouterProvider } from "react-router-dom";

import router from "routes";

// project imports
import Locales from "ui-component/Locales";

import RTLLayout from "ui-component/RTLLayout";
import Snackbar from "ui-component/extended/Snackbar";
import ThemeCustomization from "themes";

import TagManager from "react-gtm-module";
import { TAG_MGR_ARGS } from "./config";

//preload images
import dashboard from "assets/images/landing/freeze-carbon-landing_hero_v3.svg";
import dashboard_mobile from "assets/images/landing/freeze-carbon-landing-mobile.png";
import Buyer from "assets/images/landing/Buyer.svg";
import Seller from "assets/images/landing/Seller_v03.svg";
import Market from "assets/images/landing/marketplace.png";
import logo from "assets/images/logo.png";
import { IntlProvider } from "react-intl";
import LanguageToggle from "views/new-website/components/LanguageToggle";

import { useDispatch, useSelector } from "react-redux";
import { activeItem } from "store/slices/menu";
import HubSpotCookieBanner from "views/new-website/components/HubspotCookieBanner";
import HubSpotEmbed from "views/new-website/components/HubspotCookieBanner";
import { CSSProperties } from "react";

declare global {
  interface Window {
    _hsp?: any[];
  }
}

function App() {
  const [token, setToken] = React.useState(null as string | null);
  const dispatch = useDispatch();

  useEffect(() => {
    if (getCookieConsentValue()) {
      TagManager.initialize(TAG_MGR_ARGS);
    }
  });

  console.log(getLocales());
  console.log(getCurrencies());

  function getLocation() {
    if ("geolocation" in navigator) {
      // Check if geolocation is supported/enabled on current browser
      navigator.geolocation.getCurrentPosition(
        function success(position) {
          // for when getting location is a success
          console.log(
            "latitude",
            position.coords.latitude,
            "longitude",
            position.coords.longitude
          );
        },
        function error(error_message) {
          // for when getting location fails
          console.error(
            "An error has occurred while retrieving location",
            error_message
          );
        }
      );
    } else {
      // geolocation is not supported
      // get your location another way
      console.log("geolocation is not enabled on this browser");
    }
  }

  // Call the function to get the location
  // getLocation();
  // rebuild

  const messages = {
    "en-US": {
      welcome: "Welcome",
      heroHeading: "Quality & Simplicity in Carbon Credits",
      heroDesc:
        "Freeze Carbon curates carbon projects matching your culture and DNA. Deep quality analysis and transparent assessment to mitigate risks.",
      home: "Home",
      clients: "clients",
      homeHeading:
        "Build a diversified portfolio from a variety of project types",
      services: "How it works",
      knowledge: "Knowledge",
      customers: "References",
      about: "Mission",
      ourProjects: "Our Projects",
      getStarted: "Start now",
      exploreProjects: "Start now",
      signIn: "Login",
      projects: "Projects",
      biochar: "Biochar",
      erw: "Enhanced Rock Weathering",
      dac: "Direct Air Capture",
      biomass_burial: "Biomass Burial",
      river_hydro: "River Hydro",
      methane_gas: "Methane Gas",
      safe_drinking_water: "Safe Drinking Water",
      energy_efficiency: "Energy Efficiency",
      ocean_mineralization: "Ocean Mineralization",
      certifiers_we_partner_with: "Standards we work with closely",
      end_to_end: "End-to-end service",
      end_carbon_credit_service:
        "The most stringent quality assessment and{break} the most conservative approval process in the world",
      personalized_carbon_credit_strategy:
        "Personalized carbon credit strategy",
      portfolio_matching_experts:
        "Our experts tailor a portfolio of projects matching your company's DNA, industry and supply chain, or get inspiration from our pre-built portfolios.",
      quality_analysis_risk_mitigation: "Quality analysis & risk mitigation",
      footer_quality_analysis_risk_mitigation:
        "Quality analysis & risk mitigation",
      detailed_process_description:
        "Leverage our detailed 3-step process including interviews and questioners going beyond the certification process. Only approving 16% of the project we analyze.",
      cost_effective_procurement: "Cost-effective procurement",
      footer_cost_effective_procurement: "Cost-effective procurement",

      cost_description:
        "Freeze Carbon has agreements with all projects, so you can procure from multiple companies with one single contract in place whilst benefiting from our volume discounts.",
      growth_partners_climate_communities:
        "We are members of these communities",
      learning_academy: "Learning academy",
      learn: "Learn",
      blog: "Blog",
      read: "Read",
      testimonials: "References and inspiration that convince",
      get_started_find_local_and_global_projects:
        "We set up your account and contact you via email within 24 hours.",
      get_started_find_local_and_global_projects_sub:
        "Here we show you CO₂ certificates from all over the world and from a wide variety of project types, from biochar to methane gas avoidance. ",
      book_your_free_consultation_and_demo_to_get_started_right_away:
        "Speak with our carbon experts.",
      book_your_free_consultation_and_demo_to_get_started_right_away_sub:
        "In this session, we will explain how you can use Freeze Carbon to find climate projects, select the right carbon credits and confidently neutralize residual emissions.",
      services_sub: "Protect your reputation and contribute to global cooling",
      service1_desc1:
        "Our experts tailor a portfolio of projects matching your ESG goals (e.g. SBTi, TÜV), company's DNA, industry and supply chain.",
      service1_desc2:
        "Get inspired by our pre-build portfolios or custom design one with our experts.",
      service1_desc3:
        "We support your climate contribution ambition or accelerate your journey towards net-zero emissions.",
      service2_desc1:
        "Detailed 3-step process including interviews and questioners going beyond the certification process. Only approving 16% of the project we analyze.",
      service2_desc2: "Ongoing monitoring and tracking of each project.",
      service2_desc3:
        "Comprehensive 5-page report on each project, providing you with all details to make an informed purchase",
      service3_desc1:
        "Freeze Carbon has agreements with all projects, so you can procure from multiple companies with one single contract in place.",
      service3_desc2:
        "Utilize secure payment options, acting as an escrow service.",
      mission:
        "	 Freeze Carbon is your partner in the battle to find the best way possible to balance unavoidable emissions. Connecting companies with carbon projects whilst creating transparency and trust is our ambition.  Together, we can achieve a net-zero society.",
      principle: "Principles",
      together: "Together",
      together_desc:
        "Only as a collective of market participants, companies and teammates can we make the impossible possible.",
      simple: "Simple",
      simple_desc:
        "We break down the very complex topic of carbon credits into easily digestible pieces.",
      transparent: "Transparent",
      transparent_desc:
        "We take a very close look at carbon projects so that we understand all the details and can communicate them openly to our customers.",
      customer1_data:
        "The climate projects on Freeze Carbon fit perfectly with our ESG strategy and the SDGs that we promote. That's why we are now buying credits for the second time in a row.",
      customer1_title:
        "Innovation Expert & Project Lead CO₂ Taskforce, EnPulse",
      customer1_desc: "Robin Knapp",
      customer2_data:
        "Credibility and quality play a critical role in the market for carbon credits. Carbon Freeze is the “Honest Marketplace” here and offers a platform on which companies can find and invest in high-quality projects.",
      customer2_title: "Managing Director, VfU",
      customer2_desc: "Henrik Ohlsen",
      customer3_data:
        "Supported carbon projects in Cambodia and Germany to avoid methane emissions and remove carbon for their 2023 emissions.",
      customer3_title: "Sustainability Manager,",
      customer3_desc: "Mobility Service provider",
      customer4_data:
        "Neutralized all residual emissions they had for one of their consumer-facing companies. ",
      customer4_title: "Sustainability Manger,",
      customer4_desc: "Telecommunication",
      // customer5_data:
      //   '"We like your broad mix of projects, especially that you have many innovative types like biochar and rock weathering."',
      // customer5_title: "Sustainability Manager,",
      // customer5_desc: "Insurance Company",
      // customer6_data:
      //   '"We like that we can pick projects ourselves and actually understand what is behind each. It was very easy to understand each project."',
      // customer6_title: "ESG Analyst,",
      // customer6_desc: "Investment Firm",
      legalNotice: "Legal notice",
      dataPrivacy: "Data privacy",
      company: "Company",
      header_bullet1: "Cost-efficient procurement",
      header_bullet2: "Complete transparency",
      header_bullet3: "Projects matching your company's DNA",
      gettingStartedHeader: "Sign up to get access to our carbon projects.",
      meeting_division: "OR",
      testimonials_sub:
        "Discover how renowned companies are neutralizing their residual emissions with Freeze Carbon",
      thankYouText: "Thank you!",
      linkedInFollow: "Follow us on LinkedIn",
      our_approach_heading: "Our approach",
      about_desc1:
        "Freeze Carbon was called to live with the mission to create transparency into what a “good” carbon project actually looks like. ",
      about_desc2:
        "We developed the Extra Mile Quality Analysis (“EMQA”) to achieve comparability and transparency in the carbon market. Our multistep process is built like an onion. Every time the assessment of one layer was successful, we move on to the next until we reach the core. ",
      about_desc3:
        "We pre-qualify these projects, so our customers only have to deal with positive projects and can then objectively assess each detail before making a purchase decision. ",
      about_desc4:
        "EMQA was developed by combining conservative measures of the combination of the leading certification institutions and industry experts. ",
      about_desc5:
        "We have curated a list of projects which successfully passed this process. This offering is diverse in project types and regions. ",
      about_desc6:
        "We are not bound to a specific project type, certification institute or country. We are bound by the commitment towards our clients to find great projects for them. ",
      about_desc7:
        "You will therefore find carefully selected projects which you can quickly read up on and get comfortable with before buying carbon credits. ",
      teamHeading: "Our team",
      contactHeading: "Do you have any questions?",
      contactTitle: "Contact",
      OurProjects_title:
        "Hand picked carbon projects{break} which truely contribute to climate action",
      project_one: "Recycling of biowaste in Germany",
      project_two: "Swiss precision biochar",
      project_three: "Turning animal manure into biogas / Methane avoidance",
      project_four: "Direct Air Capture by leveraging existing infrastructure",
      project_five: "Removing carbon and fertilizing tropical soils",
      project_six: "Wells for clean water",
      project_class: "Project class",
      carbon_avoidance: "Carbon avoidance",
      carbon_removal: "Carbon removal",
      project_type: "Project type",
      certification_standard: "Certification standard",
      biogas: "Biogas",
      clean_water: "Safe water",
      erwShort: "Enhanced Rock Weathering",
      learnMoreButton: "Learn more",
      learnMoreArrow: "Take action to cool our planet today",
      learnMoreHeading:
        "Learn more about Freeze Carbon's{break} hand-picked climate projects",
      learnMoreData: "Climate neutrality in just a few steps",
      learnMoreButton1: "Request free account",
      learnMoreButton2: "Talk to a climate expert",
      firstSteps: "First steps",
      step1_one: "Sign up ",
      step1_two: "here",
      step1_three: " to get access to Freeze Carbon",
      step2: "View and compare the best carbon projects on the planet",
      step3: "Build a custom portfolio - with the help of our climate experts",
      step4: "Buy carbon credits from multiple projects in one place",
      step5: "Receive all data points for your CSRD or sustainability report",
      extramiles: "The Extra Mile in Quality",
      extramilesDesc: "The most stringent selection process ",
      extramilesstep1:
        "First, we define which project types provide the highest safeguards and most accurate climate impact calculation. Additionally, we look for projects contributing to the United Nations 17 Social Development Goals and support these beyond a climate impact.",
      extramilesstep2:
        "Secondly, we screen all projects in these categories using external sources. For this, we review the project’s certification document and related climate impact calculations, as well as publicly available information.",
      extramilesstep3:
        "Thirdly, we conduct a detailed quality analysis above and beyond the requirements of the certification standard to ensure only the best of the best projects are listed on Freeze Carbon. This is the most extensive part and most vital to our work as we focus on quality over quantity.",
      extramilesConcl:
        "Review our 10 page due diligence report on all our projects to get a detailed view into each project and make an informed purchase decision.",
    },
    "de-DE": {
      welcome: "Willkommen",
      heroHeading:
        "Erreichen Sie unkompliziert die Klimaziele Ihres Unternehmens",
      heroDesc:
        "Freeze Carbon stellt CO₂-Projekte zusammen, die zu Ihrer Unternehmenskultur und DNA passen.",
      homeHeading:
        "Erstellen Sie ein diversifiziertes Portfolio aus einer Vielzahl von Projekttypen",
      home: "Übersicht",
      clients: "kunden",
      services: "So funktioniert’s",
      knowledge: "Wissen",
      customers: "Referenzen",
      about: "Mission",
      getStarted: "Jetzt starten",
      signIn: "Login",
      exploreProjects: "Jetzt starten",
      ourProjects: "Klimaprojekte",
      projects: "Projekte",
      biochar: "Pflanzenkohle (Biochar)",
      erw: "Gesteinsmehl (Enhanced Rock Weathering)",
      dac: "Direct Air Capture",
      biomass_burial: "Biomasse vergraben",
      river_hydro: "Wasserkraft (Flüsse)",
      methane_gas: "Methangas vermeidung",
      safe_drinking_water: "Sauberes Trinkwasser",
      energy_efficiency: "Energieeffizienz",
      ocean_mineralization: "Ozean mineralisierung",
      certifiers_we_partner_with: "Enge Zusammenarbeit mit Standards",
      end_to_end: "Komplettservice",
      end_carbon_credit_service:
        "Die strengste Qualitätsprüfung und{break} das konservativste Zulassungsverfahren der Welt",
      personalized_carbon_credit_strategy:
        "Personalisierte Strategie für CO₂-Zertifikate",
      quality_analysis_risk_mitigation: "Qualitätsanalyse & Risikominderung",
      footer_quality_analysis_risk_mitigation:
        "Qualitätsanalyse & Risikominderung",
      detailed_process_description:
        " Nutzen Sie die Berichte unserer detaillierten dreistufigen Qualitätsanalyse. Die Interviews mit Projektentwicklern gehen tiefer als der Zertifizierungsprozess. ",
      portfolio_matching_experts:
        "Unsere Experten stellen für Sie ein Projektportfolio zusammen, das auf Ihre Unternehmens-DNA, Branche und Lieferkette abgestimmt ist, oder lassen Sie sich von unseren vorgefertigten Portfolios inspirieren.",
      cost_effective_procurement: "Kostenoptimierter Einkauf",
      footer_cost_effective_procurement: "Kostenoptimierter Einkauf",

      cost_description:
        "Freeze Carbon hat mit allen Projektentwicklern Vereinbarungen getroffen, so dass Sie mit einem einzigen Vertrag mit uns bei mehreren Unternehmen einkaufen und von unseren Mengenrabatten profitieren können.",

      growth_partners_climate_communities:
        "Wir sind Mitglied bei den wichtigsten Netzwerken",
      learn: "Lesen",
      learning_academy: "Lernen",
      blog: "Blog",
      read: "Lesen",
      testimonials: "Referenzen und Inspirationen, die überzeugen",
      get_started_find_local_and_global_projects:
        "Wir richten Ihren Zugang ein und benachrichtigen Sie innerhalb von 24 Stunden per E-Mail.",
      get_started_find_local_and_global_projects_sub:
        "Hier zeigen wir Ihnen CO₂-Zertifikate aus aller Welt und von den verschiedensten Projektarten, von Pflanzenkohle bis Methangas-Vermeidung. ",
      book_your_free_consultation_and_demo_to_get_started_right_away:
        "Sprechen Sie mit{break}  unseren CO₂-Experten.",
      book_your_free_consultation_and_demo_to_get_started_right_away_sub:
        "Wir erklären Ihnen in diesem Termin, wie Sie mit Freeze Carbon Klimaprojekte finden, die passenden Zertifikate auswählen und unkompliziert Ihre Restemissionen neutralisieren.",
      services_sub:
        "Schützen Sie Ihre Reputation und tragen Sie zur globalen Abkühlung bei",
      service1_desc1:
        "Unsere Experten stellen Ihnen ein Projektportfolio zusammen, das zu Ihren ESG-Zielen (z.B. SBTi, TÜV), Ihrer Unternehmens-DNA, Branche und  Lieferkette passt. Damit Sie glaubwürdige Belege für Ihre Projektauswahl haben. ",
      service1_desc2:
        "Lassen Sie sich von unseren vorgefertigten Portfolios inspirieren oder entwerfen Sie gemeinsam mit unseren Experten ein individuelles Portfolio.",
      service1_desc3:
        "Wir unterstützen Sie bei der Umsetzung Ihres Contribution Claims oder beschleunigen Ihrer Reise in Richtung Netto-Null-Emissionen.",
      service2_desc1:
        "Detailliertes 3-stufiger Qualitätsprozess mit Interviews und Fragebögen, der über den ursprünglichen Zertifizierungsprozess hinausgeht. Nur 16% der von uns analysierten Projekte werden für Sie zum Kauf freigegeben. Sie müssen Sich nicht durch lange Listen unqualifizierter Projekte arbeiten. ",
      service2_desc2: "Laufende Überwachung jedes Projekts.",
      service2_desc3:
        "Umfassender 5-seitiger Bericht über jedes Projekt, der Ihnen alle Details für eine fundierte Kaufentscheidung liefert.",
      service3_desc1:
        "Freeze Carbon hat Vereinbarungen und Verträge mit allen Projektentwicklern, sodass Sie mit einem einzigen Vertrag Zertifikate von mehreren Unternehmen einkaufen können.",
      service3_desc2: "Nutzen Sie unsere sichere Zahlungsoption. ",
      mission:
        "Freeze Carbon ist Ihr Partner im Kampf gegen unvermeidbare Emissionen. Unser Ziel ist es, Unternehmen und CO₂-Projekte zusammenzubringen und gleichzeitig Transparenz und Vertrauen zu schaffen. Gemeinsam können wir eine Netto-Null-Gesellschaft erreichen.",
      principle: "Werte",
      together: "Zusammen",
      together_desc:
        "Nur als ein Kollektiv von Marktteilnehmern, Unternehmen und Teamkollegen können wir das Unmögliche möglich machen.",
      simple: "Einfach",
      simple_desc:
        "Wir brechen das sehr komplexe Thema von CO₂-Zertifikaten in leicht verdauliche Stücke.",
      transparent: "Transparent",
      transparent_desc:
        "Wir schauen uns Klimaschutzprojekte sehr genau an, damit wir alle Details verstehen und diese offen an unsere Kunden kommunizieren können.",
      customer1_data:
        "Die Klimaprojekte auf Freeze Carbon passen ausgezeichnet zu unserer ESG Strategie und den SDGs, die wir fördern. Daher kaufen wir jetzt auch schon zum zweiten Mal in Folge.",
      customer1_title:
        "Innovation Expert & Project Lead CO₂ Taskforce, EnPulse",
      customer1_desc: "Robin Knapp",
      customer1_company: "EnPulse",
      customer2_data:
        "Im Markt für CO₂-Zertifikate spielen Glaubwürdigkeit und Qualität die entscheidende Rolle. Carbon Freeze ist hier der „Honest Marketplace” und bietet eine Plattform an, auf der Unternehmen hochwertige Projekte finden und in sie investieren können.",
      customer2_title: "Managing Director,{break} VfU",
      customer2_desc: "Henrik Ohlsen",
      customer3_data:
        "Unterstützte CO₂-Projekte in Kambodscha und Deutschland, um Methanemissionen zu vermeiden und CO₂ für ihre Restemissionen im Jahr 2023 zu entfernen.",
      customer3_title: "Nachhaltigkeitsmanager,",
      customer3_desc: "Mobilitätsdienstleister",
      customer4_data:
        "Neutralisierung aller verbleibenden Restemissionen, die bei einem ihrer Tochterunternehmen anfallen.",
      customer4_title: "Nachhaltigkeitsbeauftragte,",
      customer4_desc: "Telekommunikation",
      // customer5_data:
      //   '"Uns gefällt Ihre breite Mischung von Projekten, vor allem, dass Sie viele innovative Arten wie Biokohle und Gesteinsmehl haben."',
      // customer5_title: "Nachhaltigkeitsbeauftragte,",
      // customer5_desc: "Telekommunikation",
      // customer6_data:
      //   '"Uns gefällt, dass wir selbst Projekte auswählen können und tatsächlich verstehen, was dahinter steckt. Es war sehr einfach, jedes Projekt kennenzulernen."',
      // customer6_title: "ESG-Analyst,",
      // customer6_desc: "Investmentfirma",
      legalNotice: "Impressum",
      dataPrivacy: "Datenschutz",
      company: "Firma",
      header_bullet1: "Kosteneffizienter Einkaufen",
      header_bullet2: "Vollständige Transparenz",
      header_bullet3: "Passende Projekte für Ihr Portfolio",
      gettingStartedHeader:
        "Erhalten Sie jetzt Zugang zu{break} einer Vielzahl an Klimaprojekten.",
      meeting_division: "ODER",
      testimonials_sub:
        "Entdecken Sie, wie renommierte Unternehmen über Freeze Carbon Ihren CO₂-Footprint signifikant verbessern",
      thankYouText: "Danke schön!",
      linkedInFollow: "Folgen Sie uns auf LinkedIn",
      our_approach_heading: "Unsere Philosophie",
      about_desc1:
        "Wir haben Freeze Carbon ins Leben gerufen, um Transparenz zu schaffen, wie ein „gutes“ CO₂-Projekt tatsächlich aussieht. ",
      about_desc2:
        "Dazu haben wir die Extra Mile Quality Analysis („EMQA“) entwickelt, um Vergleichbarkeit und Transparenz zwischen CO₂-Zertifikaten zu schaffen. Unser mehrstufiger Prozess ist wie eine Zwiebel aufgebaut. Jedes Mal, wenn die Bewertung einer Schicht erfolgreich war, gehen wir zur nächsten über, bis wir den Kern erreichen. ",
      about_desc3:
        "Wir prüfen diese Projekte vorab, sodass sich unsere Kunden nur mit positiven Projekten befassen müssen und dann jedes Detail objektiv bewerten können, bevor sie eine Kaufentscheidung treffen. ",
      about_desc4:
        "EMQA wurde durch die Kombination konservativer Maßnahmen der führenden Zertifizierungsinstitutionen und Branchenexperten entwickelt. ",
      about_desc5:
        "Wir haben eine Liste von Projekten zusammengestellt, die diesen Prozess erfolgreich durchlaufen haben. Dieses Angebot ist nach Projekttypen und Regionen diversifiziert. ",
      about_desc6:
        "Wir sind nicht an einen bestimmten Projekttyp, ein bestimmtes Zertifizierungsinstitut oder ein bestimmtes Land gebunden. Wir sind unseren Kunden gegenüber verpflichtet, großartige Projekte für sie zu finden. ",
      about_desc7:
        "Sie werden daher sorgfältig ausgewählte Projekte finden, über die Sie sich schnell informieren und mit denen Sie sich vertraut machen können, bevor Sie Emissionsgutschriften kaufen. ",
      teamHeading: "Unser team",
      contactHeading: "Sie haben noch Fragen?",
      contactTitle: "Kontakt",
      OurProjects_title:
        "Handverlesene CO₂-Projekte,{break} die wirklich zum Klimaschutz beitragen",
      project_one: "Recycling von Bioabfällen in Deutschland",
      project_two: "Biochar mit Schweizer Präzision",
      project_three: "Tiermist für Biogas / Methan-Vermeidung",
      project_four: "Direct Air Capture in Industriegebieten",
      project_five: "CO₂ Entfernung und Düngung tropischer Böden",
      project_six: "Brunnen für sauberes Wasser",
      project_class: "Projektklasse",
      carbon_avoidance: "CO₂-Vermeidung",
      carbon_removal: "CO₂-Entfernung",
      project_type: "Projekttyp",
      certification_standard: "Zertifizierungsstandard",
      biogas: "Biogas",
      clean_water: "Trinkwasser",
      erwShort: "Gesteinsmehl",
      learnMoreArrow: "Tragen Sie aktiv zum Klimaschutz bei",
      learnMoreHeading:
        "Erfahren Sie mehr über die handverlesenen{break} Klimaprojekte von Freeze Carbon",
      learnMoreData: "In wenigen Schritten zur Klimaneutralität",
      learnMoreButton1: "Zugang beantragen",
      learnMoreButton2: "Mit einem Klimaexperten sprechen",
      firstSteps: "Erste Schritte",
      step1: "Melden Sie sich hier an, um Zugang zu Freeze Carbon zu erhalten",
      step1_one: "Melden Sie sich ",
      step1_two: "hier",
      step1_three: " an, um Zugang zu Freeze Carbon zu erhalten",
      step2:
        "Sehen und vergleichen Sie die besten Klimaschutzprojekte der Welt",
      step3:
        "Erstellen Sie ein maßgeschneidertes Portfolio - mit Hilfe unserer Klimaexperten",
      step4:
        "Kaufen Sie CO₂-Zertifikate von mehreren Projekten, gesammelt an einem Ort",
      step5:
        "Erhalten Sie alle Datenpunkte für Ihren CSRD order Nachhaltigkeitsbericht",
      extramiles: "Die Extra Meile in Qualität",
      extramilesDesc: "Der weltweit strengste Auswahlprozess",
      extramilesstep1:
        "Zunächst legen wir fest, welche Projekttypen die höchsten Sicherheitsvorkehrungen und die genaueste Berechnung der Klimaauswirkungen bieten. Außerdem suchen wir nach Projekten, die zu den 17 sozialen Entwicklungszielen der Vereinten Nationen (Social Development Goals) beitragen.",
      extramilesstep2:
        "Zweitens überprüfen wir alle Projekte in diesen Kategorien anhand extern verfügbarer Quellen. Dazu prüfen wir das Zertifizierungsdokument des Projekts und die damit verbundenen Berechnungen der Klimaauswirkungen sowie Pressemitteilungen und öffentlich verfügbare Informationen.",
      extramilesstep3:
        "Drittens führen wir eine detaillierte Qualitätsanalyse durch, die über die Anforderungen des Zertifizierungsstandards deutlich hinausgeht. Somit können wir sicherstellen, dass nur die besten der besten Klimaschutzprodukte bei Freeze Carbon gelistet werden. Dies ist der umfangreichste und wichtigste Teil unserer Arbeit, da wir uns auf Qualität statt Quantität konzentrieren.",
      extramilesConcl:
        "Lesen Sie unseren zehnseitigen Due-Diligence-Bericht zu allen unseren Projekten, um einen detaillierten Einblick in jedes Projekt zu erhalten und eine fundierte Kaufentscheidung zu treffen.",
    },
  };

  const browserLanguage = navigator.language;
  const systemLanguage = getLocales()[0].languageCode;

  // ABCD testing landing pages
  const routesToGerman = [
    "/kompensation",
    "/carbon-credits",
    "/CO₂-zertifikate",
    "/klimaschutzprojekt",
  ];

  const shouldSetToGerman = routesToGerman.some((route) =>
    window.location.pathname.includes(route)
  );

  React.useEffect(() => {
    if (shouldSetToGerman) {
      setLanguage("de-DE");
    }
  }, [shouldSetToGerman]);

  const languageToUse =
    systemLanguage.startsWith("de") || browserLanguage.startsWith("de")
      ? "de-DE"
      : "en-US";

  const selectedItem =
    sessionStorage.getItem("browserLanguage") || languageToUse;
  const [language, setLanguage] = React.useState(selectedItem);

  // const languageToUse = "en-US";

  // Select the messages based on the determined language
  const selectedMessages = messages[language];

  return (
    <ThemeCustomization>
      {/* RTL layout */}
      <RTLLayout>
        <Locales>
          {/* <div className="language-toggle">
            <input
              type="radio"
              id="english"
              name="language"
              value="en-US"
              checked={language === "en-US"}
              onChange={handleLanguageChange}
            />
            <label htmlFor="english">English</label>

            <input
              type="radio"
              id="german"
              name="language"
              value="de-DE"
              checked={language === "de-DE"}
              onChange={handleLanguageChange}
            />
            <label htmlFor="german">Deutsch</label>
          </div> */}
          <CookieConsent
            containerClasses="cookie-banner"
            buttonClasses="cookie-button"
            declineButtonClasses="cookie-button"
            style={{
              backgroundColor: "white",
              boxShadow: "0 -1px 8px 0 rgba(0,0,0,0.5)",
              flexDirection: "column",
              color: "black",
              padding: "1em 5em",
            }}
            buttonStyle={{
              padding: "1em 5em",
              backgroundColor: "#2CA4E6",
              color: "white",
              fontSize: "16px",
              margin: "15px 10px 15px 0",
              width: "200px",
            }}
            declineButtonStyle={{
              padding: "1em 5em",
              backgroundColor: "#2CA4E6",
              color: "white",
              fontSize: "16px",
              margin: "15px 10px 15px 0",
              width: "200px",
            }}
            contentStyle={{
              flex: "none",
              lineHeight: "2em",
            }}
            onAccept={(acceptedByScrolling) => {
              if (acceptedByScrolling) {
              } else {
                TagManager.initialize(TAG_MGR_ARGS);
              }
            }}
            enableDeclineButton
            buttonText="Accept"
            declineButtonText="Decline"
          >
            <h1>Usage of Cookies </h1>
            We would like to use marketing cookies to better understand your use
            of this website, to improve our website and to share information
            with our advertising partners. Your consent also covers the transfer
            of your Personal Data in unsafe third countries, involving the risk
            that public authorities may access your Personal Data for
            surveillance and other purposes w/o effective remedies. Detailed
            information about can be found in our Privacy Statement.
          </CookieConsent>
          <>
            {/* HubSpot Cookie Banner */}
            {/* <button
              type="button"
              id="hs_show_banner_button"
              style={{
                backgroundColor: "#0B496B",
                border: "1px solid #0B496B",
                borderRadius: "3px",
                padding: "10px 16px",
                textDecoration: "none",
                color: "#fff",
                fontFamily: "inherit",
                fontSize: "inherit",
                fontWeight: "normal",
                lineHeight: "inherit",
                textAlign: "left",
                textShadow: "none",
                cursor: "pointer",
              }}
              onClick={() => {
                var _hsp = (window._hsp = window._hsp || []);
                _hsp.push(["showBanner"]);
              }}
            >
              Cookie Settings
            </button> */}

            <IntlProvider locale={language} messages={selectedMessages}>
              <main className="py-1">
                <RouterProvider router={router} />
              </main>
            </IntlProvider>

            <Snackbar />
          </>

          {/* Preload images */}
          <div id="preload">
            <img src={dashboard} />
            <img src={dashboard_mobile} />
            <img src={Buyer} />
            <img src={Seller} />
            <img src={Market} />
            <img src={logo} />
          </div>
        </Locales>
      </RTLLayout>
    </ThemeCustomization>
  );
}
export default App;
