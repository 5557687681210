// material-ui
import { useTheme, styled } from "@mui/material/styles";

// project imports
import HubspotForm from "react-hubspot-form";
import FooterSection from "views/pages/landing/FooterSection";

// assets
import headerBackground from "assets/images/landing/bg-header.jpg";

import { gridSpacing } from "store/constant";

import {
  Button,
  Card,
  CardContent,
  Container,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  OutlinedInput,
  TextField,
  Typography,
  Box,
} from "@mui/material";
import NavBar from "views/new-website/components/NavBar";
import Footer from "views/new-website/components/Footer";
import React from "react";
import { getLocales } from "react-native-localize";

const HeaderWrapper = styled("div")(({ theme }) => ({
  backgroundImage: `url(${headerBackground})`,
  backgroundSize: "100% 800px",
  backgroundAttachment: "fixed",
  backgroundRepeat: "no-repeat",
  textAlign: "center",
  // paddingTop: 30,
  [theme.breakpoints.down("md")]: {
    paddingTop: 0,
  },
}));

/*<HubSpotContactForm 
            region="eu1"
            portalId="26684379"
            formId='38e3a863-181e-4729-af63-993a6522cf0d'/>*/

const SectionWrapper = styled("div")({
  paddingTop: 100,
  paddingBottom: 100,
  minWidth: "600px",
});

// ==============================|| SAMPLE PAGE ||============================== //

const ViewsContactLong = () => {
  const theme = useTheme();

  const browserLanguage = navigator.language;
  const systemLanguage = getLocales()[0].languageCode;

  const languageToUse =
    systemLanguage.startsWith("de") || browserLanguage.startsWith("de")
      ? "de-DE"
      : "en-US";

  const selectedItem =
    sessionStorage.getItem("browserLanguage") || languageToUse;
  const [language, setLanguage] = React.useState(selectedItem);

  const handleClick = () => {
    console.log("first");
    window.location.pathname = "/thank-you";
  };

  return (
    <>
      {language === "de-DE" ? (
        <Box sx={{ margin: "auto", width: "100%" }}>
          <HubspotForm
            portalId="26684379"
            formId="ae846bdc-ebda-487e-a0a3-2a1b132e549d"
            onSubmit={() => console.log("Submit!")}
            onFormSubmitted={handleClick}
            onReady={(form) => console.log("Form ready!")}
            loading={<div>Loading...</div>}
          />
        </Box>
      ) : (
        <Box sx={{ margin: "auto", width: "100%" }}>
          <HubspotForm
            portalId="26684379"
            formId="c12cec23-e3e1-4301-8fea-b89b5eb53c05"
            onSubmit={() => console.log("Submit!")}
            onFormSubmitted={handleClick}
            onReady={(form) => console.log("Form ready!")}
            loading={<div>Loading...</div>}
          />
        </Box>
      )}
    </>
  );
};

export default ViewsContactLong;
