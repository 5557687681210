import React from "react";
import gp1 from "../../../assets/images/new-icons/gp1.svg";
import gp2 from "../../../assets/images/new-icons/gp2.svg";
import gp3 from "../../../assets/images/new-icons/gp3.svg";
import gp4 from "../../../assets/images/new-icons/gp4.svg";
import gp5 from "../../../assets/images/new-icons/gp5.svg";
import gp6 from "../../../assets/images/new-icons/gp6.svg";
import { FormattedMessage } from "react-intl";
import { useEffect, useRef, useState } from "react";
import via from "../assets/images/clients/via.png";
import vfu from "../assets/images/clients/vfu.png";
import dkv from "../assets/images/clients/dkv.png";

import enpulse from "../assets/images/clients/enpulse.png";

import pyur from "../assets/images/clients/pyur.png";

import Sparkasse from "../assets/images/clients/sparkasse.svg";

const ClientScroller = () => {
  const containerRef = useRef<HTMLDivElement>(null); // Specify the type of ref
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeDot, setActiveDot] = useState(0);

  const handleScrollTo = (index) => {
    if (containerRef.current) {
      // Check if containerRef.current is not null
      let scrollAmount = containerRef.current.offsetWidth * index;
      if (scrollAmount > 0) {
        scrollAmount += 30 * index;
      }
      console.log(scrollAmount);
      containerRef.current.scrollTo({
        left: scrollAmount,
        behavior: "smooth",
      });
      setActiveDot(index);
      setScrollPosition(scrollAmount);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (activeDot + 1) % 6; // Assuming there are 3 sections to scroll through
      handleScrollTo(nextIndex);
    }, 3000); // 5000 milliseconds = 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [activeDot]);

  return (
    <div>
      {" "}
      <div
        className="homepage_project_container"
        style={{ padding: "0 0 2em 0" }}
      >
        <div className="homepage_project_header_container">
          <div className="homepage_project_heading">
            <div className="homepage_project_heading_content">
              <FormattedMessage id="clients" defaultMessage="Clients" />
            </div>
          </div>
        </div>

        <div
          className="homepage_project_container_scrollable"
          ref={containerRef}
        >
          <div className="homepage_project_container_types">
            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column clients_carousel">
                <div className="homepage_project_container_types_row_column_image clients_carousel_image">
                  <img src={via} style={{ height: "100%" }} />
                </div>
              </div>
            </div>
            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column clients_carousel">
                <div className="homepage_project_container_types_row_column_image clients_carousel_image">
                  <img src={dkv} style={{ height: "100%" }} />
                </div>
              </div>
            </div>
            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column clients_carousel">
                <div className="homepage_project_container_types_row_column_image clients_carousel_image">
                  <img src={enpulse} style={{ height: "100%" }} />
                </div>
              </div>
            </div>

            {/* <div className="homepage_project_container_types_row">
          <div className="homepage_project_container_types_row_column clients_carousel">
            <div className="homepage_project_container_types_row_column_image clients_carousel_image">
              <img src={bb} />
            </div>

            <div className="homepage_project_container_types_row_column_title">
              <FormattedMessage
                id="biomass_burial"
                defaultMessage="Biomass Burial"
              />
            </div>
          </div>
        </div> */}
            {/* <div className="homepage_project_container_types_row">
          <div className="homepage_project_container_types_row_column clients_carousel">
            <div className="homepage_project_container_types_row_column_image clients_carousel_image">
              <img src={rh} />
            </div>

            <div className="homepage_project_container_types_row_column_title">
              {" "}
              <FormattedMessage
                id="river_hydro"
                defaultMessage="River Hydro"
              />
            </div>
          </div>
        </div> */}
            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column clients_carousel">
                <div className="homepage_project_container_types_row_column_image clients_carousel_image">
                  <img src={pyur} style={{ height: "100%" }} />
                </div>
              </div>
            </div>
            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column clients_carousel">
                <div className="homepage_project_container_types_row_column_image clients_carousel_image">
                  <img src={Sparkasse} style={{ height: "100%" }} />
                </div>
              </div>
            </div>
            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column clients_carousel">
                <div className="homepage_project_container_types_row_column_image clients_carousel_image">
                  <img src={vfu} style={{ height: "100%" }} />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="scroll-navigation-mobile">
        <button
          className={activeDot === 0 ? "active" : ""}
          onClick={() => handleScrollTo(0)}
        ></button>
        <button
          className={activeDot === 1 ? "active" : ""}
          onClick={() => handleScrollTo(1)}
        ></button>
        <button
          className={activeDot === 2 ? "active" : ""}
          onClick={() => handleScrollTo(2)}
        ></button>
        <button
          className={activeDot === 3 ? "active" : ""}
          onClick={() => handleScrollTo(3)}
        ></button>
        <button
          className={activeDot === 4 ? "active" : ""}
          onClick={() => handleScrollTo(4)}
        ></button>
        <button
          className={activeDot === 5 ? "active" : ""}
          onClick={() => handleScrollTo(5)}
        ></button>
      </div>
      <div className="scroll-navigation">
        <button
          className={activeDot === 0 ? "active" : ""}
          onClick={() => handleScrollTo(0)}
        ></button>
        <button
          className={activeDot === 1 ? "active" : ""}
          onClick={() => handleScrollTo(1)}
        ></button>
        <button
          className={activeDot === 2 ? "active" : ""}
          onClick={() => handleScrollTo(2)}
        ></button>
      </div>
    </div>
  );
};

export default ClientScroller;
