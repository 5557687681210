import React, { useEffect } from "react";
import NavBar from "../components/NavBar";
import Footer from "../components/Footer";
import MeetingsEmbed from "../components/MeetingsEmbed";
import { FormattedMessage } from "react-intl";
import ViewsContactLong from "views/hub-spot/contact-long";
import { useLocation } from "react-router-dom";
import ClientScroller from "ui-component/ClientScroller";
import Partners from "../components/Partners";

const GettingStarted = () => {
  return (
    <div className="getting_started_main">
      <NavBar />

      <div className="getting_started_boxes">
        <div className="getting_started_box" id="register-free-account">
          <div className="getting_started_box_content"></div>
          <div className="homepage_project_heading_data_content">
            <div
              className="homepage_project_heading_data_content_value"
              // style={{ width: "1100px" }}
            >
              <FormattedMessage
                id="gettingStartedHeader"
                defaultMessage="Sign up to get access to our carbon projects."
                values={{ break: <br /> }}
              />
              {/* Erstellen Sie ein diversifiziertes Portfolio verschiedenster
              Klimaschutzprojekten */}
            </div>
          </div>
          <div
            style={{ marginTop: "2em" }}
            className="getting_started_contact_form"
          >
            <ViewsContactLong />
          </div>
        </div>
        <div className="getting_started_custom">
          <ClientScroller />
        </div>
        <div className="getting_started_box_division"></div>
        <div
          className="getting_started_box"
          id="talk-to-expert"
          style={{ width: "100%" }}
        >
          <div className="getting_started_box_content"></div>
          <div className="homepage_project_container_types_row">
            <div className="homepage_project_heading_data_content">
              <div
                className="homepage_project_heading_data_content_value"
                // style={{ width: "1100px" }}
              >
                <FormattedMessage
                  id="book_your_free_consultation_and_demo_to_get_started_right_away"
                  defaultMessage="Speak with our carbon experts."
                  values={{ break: <br /> }}
                />
                {/* Erstellen Sie ein diversifiziertes Portfolio verschiedenster
              Klimaschutzprojekten */}
              </div>
            </div>
          </div>
          <div style={{ marginTop: "2em" }}>
            <MeetingsEmbed />
          </div>
        </div>
        <div className="getting_started_custom">
          <Partners />
        </div>
      </div>
      <Footer />
    </div>
  );
};

export default GettingStarted;
