// material-ui
import { useTheme } from "@mui/material/styles";
import {
  Container,
  Grid,
  Typography,
  Stack,
  CardMedia,
  Button,
} from "@mui/material";

// project imports
import FadeInWhenVisible from "./Animation";
import SubCard from "ui-component/cards/SubCard";
import Avatar from "ui-component/extended/Avatar";
import { Link as RouterLink } from "react-router-dom";

// assets
import Offer1 from "assets/images/learn/importance.jpg";
import Offer2 from "assets/images/learn/types.jpg";
import Offer3 from "assets/images/learn/projects.jpg";
import Offer4 from "assets/images/learn/pricing.jpg";
import Offer5 from "assets/images/learn/quality.jpg";
import Offer6 from "assets/images/learn/UN.png";

interface OfferCardProps {
  title: string;
  caption: string;
  image: string;
  target: string;
}

const OfferCard = ({ title, caption, image, target }: OfferCardProps) => {
  const theme = useTheme();
  const AvaterSx = {
    background: "transparent",
    color: theme.palette.secondary.main,
    width: 300,
    height: 200,
  };
  return (
    <SubCard
      sx={{
        bgcolor: "#f8f8f8",
        borderColor:
          theme.palette.mode === "dark"
            ? theme.palette.background.default
            : theme.palette.divider,
        "&:hover": { boxShadow: "none" },
        height: "100%",
      }}
    >
      <Stack spacing={4}>
        <Avatar variant="rounded" sx={AvaterSx}>
          <RouterLink to={target}>
            <CardMedia component="img" src={image} />
          </RouterLink>
        </Avatar>
        <Stack spacing={2}>
          <Typography variant="h4" sx={{ fontWeight: 500 }}>
            {title}
          </Typography>
          <Typography variant="body1">{caption}</Typography>
          <Button color="inherit" component={RouterLink} to={target}>
            Read more...
          </Button>
        </Stack>
      </Stack>
    </SubCard>
  );
};

// =============================|| LANDING - FEATURE PAGE ||============================= //

const FeatureSection = () => (
  <Container>
    <Grid container spacing={7.5} justifyContent="center">
      <Grid item xs={12}>
        <Grid
          container
          justifyContent="center"
          spacing={5}
          sx={{ "&> .MuiGrid-root > div": { height: "100%" } }}
        >
          <Grid item md={4} sm={6}>
            <OfferCard
              title="Carbon credits"
              caption="How carbon credits combat climate change"
              image={Offer1}
              target="/pages/learn/importance-carbon-offsetting"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <OfferCard
              title="Project types"
              caption="What are the different sources of credits"
              image={Offer2}
              target="/pages/learn/understanding-project-types"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <OfferCard
              title="Selection criteria"
              caption="What to look for in carbon credits"
              image={Offer3}
              target="/pages/learn/high-quality-projects"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <OfferCard
              title="Pricing factors"
              caption="How pricing works for CO2 credits"
              image={Offer4}
              target="/pages/learn/pricing-co2-credits"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <OfferCard
              title="Certification standards"
              caption="Who issues carbon credits"
              image={Offer5}
              target="/pages/learn/certification-standards"
            />
          </Grid>
          <Grid item md={4} sm={6}>
            <OfferCard
              title="Co-Benefits"
              caption="What are additional benefits of credits"
              image={Offer6}
              target="/pages/learn/social-benefits"
            />
          </Grid>
        </Grid>
      </Grid>
    </Grid>
  </Container>
);

export default FeatureSection;
