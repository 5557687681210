// MeetingsEmbed.js

import React, { useEffect } from 'react';
import { getLocales } from 'react-native-localize';

const MeetingsEmbed = () => {
  const browserLanguage = navigator.language;
  const systemLanguage = getLocales()[0].languageCode;

  const languageToUse =
    systemLanguage.startsWith("de") || browserLanguage.startsWith("de")
      ? "de-DE"
      : "en-US";

  const selectedItem =
    sessionStorage.getItem("browserLanguage") || languageToUse;
  const [language, setLanguage] = React.useState(selectedItem);
  useEffect(() => {
    const script = document.createElement('script');
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js';
    script.async = true;

    document.body.appendChild(script);

    return () => {
      // Cleanup the script when the component unmounts
      document.body.removeChild(script);
    };
  }, []);

  return (
    <> {language==="en-US" ?     <div className="meetings-iframe-container" data-src="https://meetings-eu1.hubspot.com/thorsten-ratzlaff/website-get-started-2?embed=true"></div>
    :     <div className="meetings-iframe-container" data-src="https://meetings-eu1.hubspot.com/thorsten-ratzlaff/vorstellung?embed=true"></div>
}</>

  );
};

export default MeetingsEmbed;
