import React from "react";
import { Link as RouterLink } from "react-router-dom";
import logo from "assets/images/logo.png";
import LinkedIn from "@mui/icons-material/LinkedIn";
import { FormattedMessage } from "react-intl";

const Footer = () => {
  return (
    <div>
      <div className="margindiv"></div>

      <div className="footer">
        <div className="footer_logo">
          <div className="homepage_appbar_logo">
            <RouterLink to="/">
              <img src={logo} alt="Freeze Carbon" width="169" />
            </RouterLink>
            <RouterLink
              to={{
                pathname:
                  "https://www.linkedin.com/company/freeze-carbon/?originalSubdomain=de",
              }}
              target="_blank"
            >
              <LinkedIn color="primary" fontSize="large" />
            </RouterLink>
          </div>
        </div>

        {/* SERVICES  */}

        <div className="footer_links_container">
          <div
            className="footer_links_container_data "
            style={{ fontWeight: "bold" }}
          >
            {" "}
            <FormattedMessage id="services" defaultMessage="Services" />
          </div>
          <div className="footer_links_container_data">
            <RouterLink
              to="/services#personalized-strategy"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <FormattedMessage
                id="personalized_carbon_credit_strategy"
                defaultMessage="Personalized carbon credit strategy"
              />
            </RouterLink>
          </div>
          <div className="footer_links_container_data">
            <RouterLink
              to="/services#quality-analysis"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <FormattedMessage
                id="footer_quality_analysis_risk_mitigation"
                defaultMessage="Quality analysis & risk mitigation"
                values={{ break: <br /> }}
              />
            </RouterLink>
          </div>

          <div className="footer_links_container_data">
            <RouterLink
              to="/services#cost-effective"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <FormattedMessage
                id="footer_cost_effective_procurement"
                defaultMessage="Cost-effective procurement"
                values={{ break: <br /> }}
              />
            </RouterLink>
          </div>

          <div
            className="footer_links_container_data"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <RouterLink
              to="/getting-started"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <FormattedMessage id="getStarted" defaultMessage="Sign up" />
            </RouterLink>
          </div>
        </div>

        {/* projects  */}

        <div className="footer_links_container">
          <div
            className="footer_links_container_data"
            style={{ fontWeight: "bold" }}
          >
            <FormattedMessage id="ourProjects" defaultMessage="Our Projects" />
          </div>
          <div className="footer_links_container_data">
            <RouterLink
              to="/our-projects"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <FormattedMessage
                id="ourProjects"
                defaultMessage="Our Projects"
              />
            </RouterLink>
          </div>
        </div>

        {/* References  */}

        <div className="footer_links_container">
          <div
            className="footer_links_container_data"
            style={{ fontWeight: "bold" }}
          >
            <FormattedMessage id="customers" defaultMessage="Customers" />
          </div>
          <div className="footer_links_container_data">
            <RouterLink
              to="/testimonials"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <FormattedMessage id="customers" defaultMessage="Customers" />
            </RouterLink>
          </div>
        </div>

        {/* ABOUT US  */}

        <div className="footer_links_container" style={{ width: "140px" }}>
          <div
            className="footer_links_container_data"
            style={{ fontWeight: "bold" }}
          >
            <FormattedMessage id="about" defaultMessage="About" />
          </div>
          <div className="footer_links_container_data">
            <RouterLink
              to="/about"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <FormattedMessage id="company" defaultMessage="company" />
            </RouterLink>
          </div>
          {/* <div className="footer_links_container_data">Contact</div> */}

          <div className="footer_links_container_data">
            {" "}
            <RouterLink
              to="/about#jobs"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              Job
            </RouterLink>
          </div>
        </div>

        <div className="footer_links_container">
          <div
            className="footer_links_container_data"
            style={{ fontWeight: "bold" }}
          >
            {" "}
            <FormattedMessage id="knowledge" defaultMessage="Knowledge" />
          </div>
          <div className="footer_links_container_data">
            <RouterLink
              to="/learn"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <FormattedMessage
                id="learning_academy"
                defaultMessage="Learning academy"
              />
            </RouterLink>
          </div>
          <div className="footer_links_container_data">
            <a
              href="https://blog.freezecarbon.com/"
              target="_blank"
              style={{
                textDecoration: "none",
                color: "inherit",
              }}
            >
              Blog
            </a>
          </div>
        </div>

        <div className="footer_links_container">
          <div
            className="footer_links_container_data"
            style={{ fontWeight: "bold" }}
          >
            Legal
          </div>
          <div className="footer_links_container_data">
            <RouterLink
              to="/legal-notice"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <FormattedMessage id="legalNotice" defaultMessage="legalNotice" />
            </RouterLink>
          </div>
          <div className="footer_links_container_data">
            <RouterLink
              to="/data-privacy-en"
              style={{ textDecoration: "none", color: "inherit" }}
            >
              <FormattedMessage id="dataPrivacy" defaultMessage="dataPrivacy" />
            </RouterLink>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
