import React, { useEffect, useRef, useState } from "react";

import { Link, Link as RouterLink } from "react-router-dom";
import { IconArrowRight } from "@tabler/icons";
import NavBar from "../components/NavBar";
import biochar from "../../../assets/images/new-icons/biochar.svg";
import erw from "../../../assets/images/new-icons/erw.svg";
import bb from "../../../assets/images/new-icons/bb.svg";
import dac from "../../../assets/images/new-icons/dac.svg";
import ee from "../../../assets/images/new-icons/ee.svg";
import rh from "../../../assets/images/new-icons/rh.svg";
import mg from "../../../assets/images/new-icons/mg.svg";
import om from "../../../assets/images/new-icons/om.svg";
import sdw from "../../../assets/images/new-icons/sdw.svg";
import gs from "../../../assets/images/new-icons/gs.svg";
import iso from "../../../assets/images/new-icons/iso.svg";
import dnv from "../../../assets/images/new-icons/dnv.svg";
import pe from "../../../assets/images/new-icons/pe.svg";
import ebc from "../../../assets/images/new-icons/ebc.svg";
import gp1 from "../../../assets/images/new-icons/gp1.svg";
import gp2 from "../../../assets/images/new-icons/gp2.svg";
import gp3 from "../../../assets/images/new-icons/gp3.svg";
import gp4 from "../../../assets/images/new-icons/gp4.svg";
import gp5 from "../../../assets/images/new-icons/gp5.svg";
import gp6 from "../../../assets/images/new-icons/gp6.svg";
import logo from "assets/images/logo.png";
import Footer from "../components/Footer";
import pccs from "../../../assets/images/new-icons/pccs.svg";
import qarm from "../../../assets/images/new-icons/qarm.svg";
import cep from "../../../assets/images/new-icons/cep.svg";
import { FormattedMessage } from "react-intl";
import Partners from "../components/Partners";

const CO2Zertifikate = () => {
  const handleServices1 = () => {};

  useEffect(() => {
    sessionStorage.setItem("browserLanguage", "de-DE");
  }, []);

  const containerRef = useRef<HTMLDivElement>(null); // Specify the type of ref
  const [scrollPosition, setScrollPosition] = useState(0);
  const [activeDot, setActiveDot] = useState(0);

  const handleScrollTo = (index: number) => {
    if (containerRef.current) {
      // Check if containerRef.current is not null
      const scrollAmount = containerRef.current.offsetWidth * index;
      console.log(scrollAmount);
      containerRef.current.scrollTo({
        left: scrollAmount,
        behavior: "smooth",
      });
      setActiveDot(index);
      setScrollPosition(scrollAmount);
    }
  };

  useEffect(() => {
    const interval = setInterval(() => {
      const nextIndex = (activeDot + 1) % 3; // Assuming there are 3 sections to scroll through
      handleScrollTo(nextIndex);
    }, 3000); // 5000 milliseconds = 5 seconds

    return () => clearInterval(interval); // Cleanup interval on component unmount
  }, [activeDot]);

  return (
    <div>
      <NavBar />

      {/* project types  */}
      <div className="homepage_hero_container">
        <video autoPlay loop muted className="homepage_hero_container_video">
          <source src="https://storage.googleapis.com/freezecarbon-website/hero.mp4" />{" "}
          {/* Update the type to 'video/quicktime' */}
          Your browser does not support the video tag.
        </video>

        <div className="homepage_hero_textbox">
          <div className="homepage_hero_section_title">
            {/* <div className="homepage_hero_frame129">
              <div className="homepage_hero_frame129_heading">
                <FormattedMessage id="welcome" defaultMessage="Welcome" />
              </div>
            </div> */}
            <div className="homepage_hero_heading">
              {/* <FormattedMessage
                id="heroHeading"
                defaultMessage="Quality & Simplicity in Carbon Credits"
              /> */}
              Erreichen Sie unkompliziert die Klimaziele Ihres Unternehmens
            </div>
            <div className="homepage_hero_text">
              {" "}
              {/* <FormattedMessage
                id="heroDesc"
                defaultMessage="Freeze Carbon curates carbon projects matching your culture and DNA. Deep quality analysis and transparent assessment to mitigate risks."
              /> */}
              Finden Sie CO2-Zertifikate, die zu Ihrer Unternehmenskultur und
              DNA passen.
            </div>
          </div>
          <div>
            {" "}
            <div className="bullet-point-item" style={{ margin: "0" }}>
              <span className="bullet-marker" style={{ color: "white" }}>
                •
              </span>
              <span
                className="bullet-text"
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.2em",
                  opacity: "1",
                }}
              >
                <FormattedMessage
                  id="header_bullet1"
                  defaultMessage="Cost-efficient procurement"
                />
              </span>
            </div>
            <div className="bullet-point-item">
              <span className="bullet-marker" style={{ color: "white" }}>
                •
              </span>
              <span
                className="bullet-text"
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.2em",
                  opacity: "1",
                }}
              >
                <FormattedMessage
                  id="header_bullet2"
                  defaultMessage="Complete transparency"
                />
              </span>
            </div>
            <div className="bullet-point-item">
              <span className="bullet-marker" style={{ color: "white" }}>
                •
              </span>
              <span
                className="bullet-text"
                style={{
                  color: "#FFFFFF",
                  fontSize: "1.2em",
                  opacity: "1",
                }}
              >
                <FormattedMessage
                  id="header_bullet3"
                  defaultMessage="Projects matching your company's DNA"
                />
              </span>
            </div>
          </div>
          <div className="heropage_hero_action">
            <RouterLink
              to="/getting-started"
              className="homepage_appbar_nav_get_started homepage_appbar_nav_get_started_co2"
            >
              {/* <FormattedMessage id="getStarted" defaultMessage="Sign up" /> */}
              Für Privatpersonen
            </RouterLink>
            <RouterLink
              to="/getting-started"
              className="homepage_appbar_nav_get_started homepage_appbar_nav_get_started_co2"
            >
              {/* <FormattedMessage id="getStarted" defaultMessage="Sign up" /> */}
              Für Unternehmen
            </RouterLink>
            {/* <div className="homepage_appbar_nav_watch_video">
            Watch Video <IconArrowRight color="white" />
          </div> */}
          </div>
        </div>
      </div>

      {/* services */}

      <div className="homepage_project_container">
        <div className="homepage_project_header_container">
          <div className="homepage_project_heading">
            <div className="homepage_project_heading_content">
              <FormattedMessage id="services" defaultMessage="Services" />
            </div>
          </div>
          <div className="homepage_project_heading_data_content">
            <div className="homepage_project_heading_data_content_value">
              {" "}
              <FormattedMessage
                id="end_carbon_credit_service"
                defaultMessage="End-to-end{break}carbon credit service"
                values={{ break: <br /> }}
              />
            </div>
          </div>
        </div>
        <div className="homepage_project_container_types">
          <RouterLink
            to="/services#personalized-strategy"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column homepage_box_height">
                <div className="service_banner_image homepage_image">
                  <img src={pccs} className="home_image_width" />
                </div>
                <div className="homepage_project_container_types_row_column_heading1">
                  <FormattedMessage
                    id="personalized_carbon_credit_strategy"
                    defaultMessage="Personalized carbon credit strategy"
                  />
                </div>
                {/* <div className="homepage_project_container_types_row_column_heading2">
                <FormattedMessage
                  id="portfolio_matching_experts"
                  defaultMessage="Our experts tailor a portfolio of projects matching your company DNA, industry and supply chain or get inspiration from our pre-built portfolios."
                />
              </div> */}
              </div>
            </div>
          </RouterLink>

          <RouterLink
            to="/services#quality-analysis"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column homepage_box_height">
                <div className="service_banner_image homepage_image">
                  <img src={qarm} className="home_image_width" />
                </div>
                <div className="homepage_project_container_types_row_column_heading1">
                  <FormattedMessage
                    id="quality_analysis_risk_mitigation"
                    defaultMessage="Quality analysis{break} & risk mitigation"
                    values={{ break: <br /> }}
                  />
                </div>
                {/* <div className="homepage_project_container_types_row_column_heading2">
                <FormattedMessage
                  id="detailed_process_description"
                  defaultMessage="Leverage our detailed 3-step process including interviews and questionnaires going beyond the certification process. Only approving 16% of the projects we analyze."
                />
              </div> */}
              </div>
            </div>
          </RouterLink>

          <RouterLink
            to="/services#cost-effective"
            style={{ textDecoration: "none", color: "inherit" }}
          >
            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column homepage_box_height">
                <div className="service_banner_image homepage_image">
                  <img src={cep} className="home_image_width" />
                </div>
                <div className="homepage_project_container_types_row_column_heading1">
                  <FormattedMessage
                    id="cost_effective_procurement"
                    defaultMessage="Cost-effective{break} procurement"
                    values={{ break: <br /> }}
                  />
                </div>
                {/* <div className="homepage_project_container_types_row_column_heading2">
                <FormattedMessage
                  id="cost_description"
                  defaultMessage="Freeze Carbon has agreements with all projects, so you can procure from multiple companies with one single contract in place whilst benefiting from our volume discounts."
                />
              </div> */}
              </div>
            </div>
          </RouterLink>
        </div>
      </div>
      <div className="homepage_project_container_action center">
        <RouterLink
          to="/getting-started"
          className="homepage_appbar_nav_get_started"
        >
          {/* <FormattedMessage id="getStarted" defaultMessage="Sign up" /> */}
          Zertifikate finden
        </RouterLink>
      </div>

      {/* certification */}

      <div className="homepage_certification_logo1">
        <div className="homepage_certification_logo1_frame118">
          <div className="homepage_certification_logo1_frame118_heading">
            {" "}
            <FormattedMessage
              id="certifiers_we_partner_with"
              defaultMessage="Certifiers we partner with"
            />
          </div>
          <div className="homepage_certification_logo1_frame118_content">
            <div className="homepage_certification_logo1_frame118_row">
              <img src={gs} />
            </div>
            <div className="homepage_certification_logo1_frame118_row">
              <img src={iso} />
            </div>
            <div className="homepage_certification_logo1_frame118_row">
              <img src={dnv} />
            </div>
            <div className="homepage_certification_logo1_frame118_row">
              <img src={pe} />
            </div>
            <div className="homepage_certification_logo1_frame118_row">
              <img src={ebc} />
            </div>
          </div>
        </div>
      </div>

      {/* services banner */}

      <div
        className="services_banner_container services_banner_container_desktop"
        id="personalized-strategy"
      >
        <div className="service_banner_data">
          <div className="service_banner_heading">
            {/* <FormattedMessage
              id="personalized_carbon_credit_strategy"
              defaultMessage="Personalized carbon credit strategy"
            /> */}
            CO₂-Zertifikate einfach und sicher kaufen
          </div>

          <div className=" bullet-point-item-para">
            Haben Sie Ihre Emissionen berechnet und Reduktionsmaßnahmen
            begonnen. Hierbei sind Sie sicherlich auf unvermeidbare Emissionen
            gestoßen. Wir helfen Ihnen qualitativ hochwertige
            Klimaschutzprojekte zu finden, damit Sie Ihre ESG Ziele sicher und
            leicht erreichen.
          </div>
          <div className=" bullet-point-item-para">
            Wir analysieren Projekte nach unserem Qualitätsprozess und lassen
            hierbei im Durchschnitt nur 16% der Kompensationsprojekte zu. Diese
            Analyse legen wir Ihnen offen, damit Sie genau wissen, was sich
            hinter dem jeweiligen Projekt verbirgt.
          </div>
          <div className="service_banner_button">
            <RouterLink
              to="/getting-started"
              className="homepage_appbar_nav_get_started"
            >
              {/* <FormattedMessage id="getStarted" defaultMessage="Sign up" /> */}
              Zertifikate finden
            </RouterLink>
          </div>
        </div>

        <div className="service_banner_image">
          <img src={pccs} className="image_width" />
        </div>
      </div>

      {/* projects */}

      <div className="homepage_project_container">
        <div className="homepage_project_header_container">
          <div className="homepage_project_heading">
            <div className="homepage_project_heading_content">
              <FormattedMessage id="projects" defaultMessage="Projects" />
            </div>
          </div>
          <div className="homepage_project_heading_data_content">
            <div
              className="homepage_project_heading_data_content_value"
              style={{ width: "800px" }}
            >
              {/* <FormattedMessage
                id="homeHeading"
                defaultMessage="Build a diversified portfolio from a variety of project types"
              /> */}
              CO₂-Zertifikate von vertrauenswürdigen Projekten
            </div>
          </div>
        </div>

        <div
          className="homepage_project_container_scrollable"
          ref={containerRef}
        >
          <div className="homepage_project_container_types">
            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column">
                <div className="homepage_project_container_types_row_column_image">
                  <img src={biochar} />
                </div>
                <div className="homepage_project_container_types_row_column_title">
                  <FormattedMessage id="biochar" defaultMessage="Biochar" />
                </div>
              </div>
            </div>
            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column">
                <div className="homepage_project_container_types_row_column_image">
                  <img src={erw} />
                </div>

                <div className="homepage_project_container_types_row_column_title">
                  <FormattedMessage
                    id="erw"
                    defaultMessage="Enhanced Rock Weathering"
                  />
                </div>
              </div>
            </div>
            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column">
                <div className="homepage_project_container_types_row_column_image">
                  <img src={dac} />
                </div>

                <div className="homepage_project_container_types_row_column_title">
                  <FormattedMessage id="dac" defaultMessage="DAC" />
                </div>
              </div>
            </div>

            {/* <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column">
                <div className="homepage_project_container_types_row_column_image">
                  <img src={bb} />
                </div>

                <div className="homepage_project_container_types_row_column_title">
                  <FormattedMessage
                    id="biomass_burial"
                    defaultMessage="Biomass Burial"
                  />
                </div>
              </div>
            </div> */}
            {/* <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column">
                <div className="homepage_project_container_types_row_column_image">
                  <img src={rh} />
                </div>

                <div className="homepage_project_container_types_row_column_title">
                  {" "}
                  <FormattedMessage
                    id="river_hydro"
                    defaultMessage="River Hydro"
                  />
                </div>
              </div>
            </div> */}
            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column">
                <div className="homepage_project_container_types_row_column_image">
                  <img src={mg} />
                </div>

                <div className="homepage_project_container_types_row_column_title">
                  {" "}
                  <FormattedMessage
                    id="methane_gas"
                    defaultMessage="Methane Gas"
                  />
                </div>
              </div>
            </div>
            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column">
                <div className="homepage_project_container_types_row_column_image">
                  <img src={sdw} />
                </div>

                <div className="homepage_project_container_types_row_column_title">
                  {" "}
                  <FormattedMessage
                    id="safe_drinking_water"
                    defaultMessage="Safe Drinking Water"
                  />
                </div>
              </div>
            </div>
            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column">
                <div className="homepage_project_container_types_row_column_image">
                  <img src={ee} />
                </div>

                <div className="homepage_project_container_types_row_column_title">
                  {" "}
                  <FormattedMessage
                    id="energy_efficiency"
                    defaultMessage="Energy Efficiency"
                  />
                </div>
              </div>
            </div>
            <div className="homepage_project_container_types_row">
              <div className="homepage_project_container_types_row_column">
                <div className="homepage_project_container_types_row_column_image">
                  <img src={om} />
                </div>

                <div className="homepage_project_container_types_row_column_title">
                  {" "}
                  <FormattedMessage
                    id="ocean_mineralization"
                    defaultMessage="Ocean Mineralization"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div className="scroll-navigation">
        <button
          className={activeDot === 0 ? "active" : ""}
          onClick={() => handleScrollTo(0)}
        ></button>
        <button
          className={activeDot === 1 ? "active" : ""}
          onClick={() => handleScrollTo(1)}
        ></button>
        <button
          className={activeDot === 2 ? "active" : ""}
          onClick={() => handleScrollTo(2)}
        ></button>
      </div>
      {/* <div className="homepage_project_container_action">
        <RouterLink
          to="/getting-started"
          className="homepage_appbar_nav_get_started"
        >
          <FormattedMessage id="getStarted" defaultMessage="Sign up" />
        </RouterLink>
      </div> */}

      {/* partners  */}

      <Partners />

      <Footer />
    </div>
  );
};

export default CO2Zertifikate;
